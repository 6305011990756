import { TicketDiscount } from '@bam/sdk';
import { DISCOUNT_TYPES } from './utils';

/**
 * @param {TicketDiscount} discount ticket discount
 * @param {number} price regular price
 */
export const applyDiscount = (discount: TicketDiscount, price: number) => {
  switch (discount.type) {
    case DISCOUNT_TYPES.AMOUNT: {
      const result = price - discount.value;
      return Math.max(result, 0);
    }
    case DISCOUNT_TYPES.PERCENTAGE: {
      const result = (price * (100 - discount.value)) / 100;
      return Math.max(result, 0);
    }
    default: {
      return price;
    }
  }
};

const choosePrice = (type: string, price: number, bestPrice: number) =>
  type === DISCOUNT_TYPES.PERCENTAGE ? bestPrice : price;

/**
 * apply discount to price and return smaller
 *
 * @param {number} price
 */
const discountReducer =
  (price: number) => (bestPrice: number, td: TicketDiscount) => {
    const discountedPrice = applyDiscount(
      td,
      choosePrice(td.type, price, bestPrice)
    );
    if (discountedPrice < bestPrice) {
      // is best price
      return discountedPrice;
    }

    return bestPrice;
  };

export const calculateOrderItemPrice = (
  price: number,
  quantity: number,
  tds: TicketDiscount[]
) => {
  if (tds == null || tds.length === 0) {
    return price * quantity;
  }
  return tds.reduce(discountReducer(price), price) * quantity;
};
