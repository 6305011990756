import { Event } from '@bam/sdk';

export const state = () => ({
  searchTerm: '' as string,
  currentEvent: null as Event,
  organizerId: null as number
});

export const mutations = {
  setSearchTerm(state, payload) {
    state.searchTerm = payload;
  },
  setCurrentEvent(state, payload) {
    state.currentEvent = {
      ...payload,
      // since descriptions might get very large and lead to a quota exceed error,
      // we do not store them (since we don't need them anyway)
      ticketConfig: payload.ticketConfig.map((tc) => {
        return { ...tc, description: undefined };
      })
    };
  },
  setOrganizerId(state, payload) {
    state.organizerId = payload;
  }
};

export const actions: any = {
  setCurrentEvent({ commit }, event) {
    commit('setCurrentEvent', event);
  }
};

export const getters = {
  getSearchTerm: (state) => {
    return state.searchTerm;
  },
  getCurrentEvent: (state) => {
    return state.currentEvent;
  },
  getOrganizerId: (state) => {
    return state.organizerId;
  }
};
